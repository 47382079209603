
import GTM from '~/helpers/gtm'
export default {
  name: 'HomePageHeroBanner',
  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  computed: {
    bannerStyle () {
      return {
        background: this.api?.data?.background || 'transparent',
      }
    },

    slidesPerView () {
      return {
        xxs: 1,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
      }
    },

    items () {
      return this.api.children.find(item =>
        ['carousel', 'brandZone'].includes(item.componentType),
      )?.children ?? []
    },
  },

  methods: {
    onBannerClick (item) {
      const { salesManagoId = '' } = this.$store.state?.user?.profile

      this.$gtm.push({ ecommerce: null })
      this.$gtm.push(GTM.trackProductSelectFromList('select_item', item, 0, salesManagoId))
    },

  },
}
