import { render, staticRenderFns } from "./HomePageHeroBanner.vue?vue&type=template&id=95f54e04&scoped=true&"
import script from "./HomePageHeroBanner.vue?vue&type=script&lang=js&"
export * from "./HomePageHeroBanner.vue?vue&type=script&lang=js&"
import style0 from "./HomePageHeroBanner.vue?vue&type=style&index=0&id=95f54e04&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95f54e04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediSnapCarousel: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/SnapCarousel/MediSnapCarousel.vue').default,BaseContainer: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseContainer.vue').default})
